import { ref, watch } from "vue";
import { getPublishedProfiles, PROFILES_FILTERS } from "./profiles/list";
import { SUBSCRIPTION_INFO } from "./subscription";

export const NAVBAR_SHOW_SETTINGS_MENU = ref(false);
export const NAVBAR_SHOW_NOTIFICATIONS_MENU = ref(false);
export const NAVBAR_SHOW_NAVITEMS_MENU = ref(false);
export const LAST_SELECTED_ELEMENT_TO_VIEW = ref(null);
export const LAST_SELECTED_PACKAGE_TO_VIEW = ref(null);
export const COUNTRY_TO_VIEW = ref(null);
export const COMPANY_TO_VIEW = ref(null);
export const ASSET_TO_VIEW = ref(null);
export const STATUS_TO_VIEW = ref(null);
export const TYPE_TO_VIEW = ref(null);
export const PRODUCT_GROUP_TO_VIEW = ref(null);
export const PRODUCT_TO_VIEW = ref(null);


watch(LAST_SELECTED_ELEMENT_TO_VIEW, async (element) => {
if (element.length > 6) {
  LAST_SELECTED_ELEMENT_TO_VIEW.value = element.slice(0,6);
  window.$message.info("You may only select up to 6 elements at a time.");
}
});
/*watch(LAST_SELECTED_ELEMENT_TO_VIEW, async (element) => {
  const isBundle = SUBSCRIPTION_INFO.bundles.find(
    (nth) => nth.code === element
  );
  if (!isBundle && element) {
    COUNTRY_TO_VIEW.value = null;
    ASSET_TO_VIEW.value = null;
    COMPANY_TO_VIEW.value = null;
    STATUS_TO_VIEW.value = null;
    TYPE_TO_VIEW.value = null;
    PRODUCT_GROUP_TO_VIEW.value = null;
    PRODUCT_TO_VIEW.value = null;
    await getPublishedProfiles();
  }
});
/*
watch(LAST_SELECTED_PACKAGE_TO_VIEW, async () => {
  ASSET_TO_VIEW.value = null;
  COMPANY_TO_VIEW.value = null;
  STATUS_TO_VIEW.value = null;
  TYPE_TO_VIEW.value = null;
  PRODUCT_GROUP_TO_VIEW.value = null;
  PRODUCT_TO_VIEW.value = null;
  await getPublishedProfiles();
});
watch(COUNTRY_TO_VIEW, async () => {
  ASSET_TO_VIEW.value = null;
  COMPANY_TO_VIEW.value = null;
  STATUS_TO_VIEW.value = null;
  TYPE_TO_VIEW.value = null;
  PRODUCT_GROUP_TO_VIEW.value = null;
  PRODUCT_TO_VIEW.value = null;
  await getPublishedProfiles();
});
watch(COMPANY_TO_VIEW, async () => {
  ASSET_TO_VIEW.value = null;
  STATUS_TO_VIEW.value = null;
  TYPE_TO_VIEW.value = null;
  PRODUCT_GROUP_TO_VIEW.value = null;
  PRODUCT_TO_VIEW.value = null;
  await getPublishedProfiles();
});
watch(STATUS_TO_VIEW, async () => {
  TYPE_TO_VIEW.value = null;
  PRODUCT_GROUP_TO_VIEW.value = null;
  PRODUCT_TO_VIEW.value = null;
});
watch(TYPE_TO_VIEW, async () => {
  PRODUCT_GROUP_TO_VIEW.value = null;
  PRODUCT_TO_VIEW.value = null;
  await getPublishedProfiles();
});
watch(PRODUCT_GROUP_TO_VIEW, async () => {
  PRODUCT_TO_VIEW.value = null;
  await getPublishedProfiles();
});
watch(PRODUCT_TO_VIEW, async () => {
  await getPublishedProfiles();
});
*/

export const MARKET_SERVICE_CATEGORY = {
  EV_AND_BATTERY: "EV_AND_BATTERY",
  STEEL_AND_ALLOYS: "STEEL_AND_ALLOYS",
  INDUSTRY_MINERALS: "INDUSTRY_MINERALS",
  TECHNOLOGY_METALS: "TECHNOLOGY_METALS",
  CRITICAL_MATERIALS: "CRITICAL_MATERIALS",
  ENERGY_TRANSITION: "ENERGY_TRANSITION",
  GEOPOLITICS: "GEOPOLITICS",
  ESG_AND_SUSTAINABILITY: "ESG_AND_SUSTAINABILITY",
  AEROSPACE_METALS: "AEROSPACE_METALS",
};

export const marketServicesCategories = {
  antimony: [
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  chromium: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
  ],
  cobalt: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
  ],
  copper: [
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS
  ],
  fluorine: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
  ],
  gallium: [
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  germanium: [
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  graphite: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
  ],
  iron: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
  lithium: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
  ],
  lithium_industry_cost_service: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
  ],
  lithium_extractive_cost_service: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
  ],
  lithium_ion_batteries: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
  ],
  manganese: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
  molybdenum: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
  nickel: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
  niobium: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
  ],
  scandium: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
  ],
  vanadium: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
  silicon: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  ree: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  rhenium: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS
  ],
  salt: [
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
  ],
  magnesium: [
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS, 
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
  ],
  tantalum: [
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  tin: [
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  titanium: [
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
  ],
  tungsten: [
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  vanadium_extractive_cost_service: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
}

export const MARKET_SERVICES = {
  Sb: "antimony",
  Cr: "chromium",
  Co: "cobalt",
  F: "fluorine",
  Ga: "gallium",
  Graphite: "graphite",
  Ge: "germanium",
  Fe: "iron",
  Li: "lithium",
  LCS: "li_cost_model_service",
  VECS: "vanadium_extractive_cost_service",
  LRCS: "lithium_refined_cost_service",
  CrECT: "chromium_extractive_cost_tracker",
  CrRCT: "chromium_refined_cost_tracker",
  FECT: "fluorspar_extractive_cost_tracker",
  MnECT: "manganese_extractive_cost_tracker",
  MnRCT: "manganese_refined_cost_tracker",
  REECS: "rare_earths_extractive_cost_service",
  EVB: "ev_and_battery_monthly_service",
  ISA: "iron_steel_and_alloys_monthly_service",
  AERO: "aerospace_monthly_service",
  SEMI: "semiconductor_monthly_service",
  SOLAR: "solar_monthly_service",
  WIND: "wind_monthly_service",
  Mg: "magnesium",
  Mn: "manganese",
  Mo: "molybdenum",
  Ni: "nickel",
  Nb: "niobium",
  REE: "ree",
  Sc: "scandium",
  V: "vanadium",
  Si: "silicon",
  SALT: "salt",
  Ta: "tantalum",
  Sn: "tin",
  Ti: "titanium",
  W: "tungsten"
};

export const MARKETING_SITE_SERVICES = {
  Sb: "antimony",
  Cr: "chromium",
  Co: "cobalt",
  F: "fluorine",
  Ga: "gallium",
  Graphite: "graphite",
  Ge: "germanium",
  Fe: "iron",
  Li: "lithium",
  Mg: "magnesium",
  Mn: "manganese",
  Mo: "molybdenum",
  Ni: "nickel",
  Nb: "niobium",
  REE: "ree",
  Sc: "scandium",
  V: "vanadium",
  Si: "silicon",
  SALT: "salt",
  Ta: "tantalum",
  Sn: "tin",
  Ti: "titanium",
  W: "tungsten"
};

export const SUBSCRIPTIONS_SERVICES = {
  Sb: "antimony",
  Cr: "chromium",
  Co: "cobalt",
  F: "fluorine",
  Ga: "gallium",
  Graphite: "graphite",
  Ge: "germanium",
  Fe: "iron",
  Li: "lithium",
  LCS: "li_cost_model_service",
  VECS: "vanadium_extractive_cost_service",
  LRCS: "lithium_refined_cost_service",
  CrECT: "chromium_extractive_cost_tracker",
  CrRCT: "chromium_refined_cost_tracker",
  FECT: "fluorspar_extractive_cost_tracker",
  MnECT: "manganese_extractive_cost_tracker",
  MnRCT: "manganese_refined_cost_tracker",
  REECS: "rare_earths_extractive_cost_service",
  EVB: "ev_and_battery_monthly_service",
  ISA: "iron_steel_and_alloys_monthly_service",
  AERO: "aerospace_monthly_service",
  SEMI: "semiconductor_monthly_service",
  SOLAR: "solar_monthly_service",
  WIND: "wind_monthly_service",
  Mg: "magnesium",
  Mn: "manganese",
  Mo: "molybdenum",
  Ni: "nickel",
  Nb: "niobium",
  REE: "ree",
  Sc: "scandium",
  V: "vanadium",
  Si: "silicon",
  SALT: "salt",
  Ta: "tantalum",
  Sn: "tin",
  Ti: "titanium",
  W: "tungsten"
}
