<template>
  <div class="py-4 container-fluid pt-0">
    <!-- <ProfilesMenu class="sticky-top pt-3" /> -->
    <div class="row">
      <div class="col-12 mb-3">
        <div style="background-color: white; border-radius: 15px;">
          <div class="card-header pb-0">
            <h4 class="proxima-blue-text inline-text">
              <span @click="tableView = 'PROFILES'" class="cursor-pointer"
                :class="tableView === 'SUPPLY_CHAIN_AGREEMENTS' || tableView === 'COMPANY_PROFILES' || tableView === 'COUNTRY_PROFILES' ? 'text-muted' : ''">
                {{ $t("ProximaList.profilesTable.title") }}
              </span>
              <span v-if="PUBLISHED_PROFILES?.assetProfiles?.length || assetsPlotted > 0">
                ({{ assetsPlotted != 0 ? assetsPlotted : PUBLISHED_PROFILES?.assetProfiles?.length ?? 0
                }})</span>
            </h4>
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6">
                <button class="button" :disabled="inProgress" @click="
                  showMap = !showMap;
                ">
                  {{
                    showMap
                      ? "Display as Table"
                      : "Display on Map"
                  }}
                </button>
              </div>
              <div v-if="showMap" class="col-lg-3 col-md-4 col-sm-6">
                <NDropdown trigger="click" style="width: 100%" :options="MAP_STYLE_OPTS" @select="updateMapStyle">
                  <button class="button" style="width: 100%; display: block" :disabled="inProgress">
                    Map Style
                  </button>
                </NDropdown>
              </div>
              <div v-if="showMap" class="col-lg-3 col-md-4 col-sm-6">
                <NDropdown trigger="click" style="width: 100%" :options="MAP_OPTS" @select="updateMapType">
                  <button class="button" style="width: 100%; display: block" :disabled="inProgress">
                    Map Projection
                  </button>
                </NDropdown>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <button class="button" :disabled="inProgress" @click="saveToPNG">
                  {{ showMap == true ? "Save To PNG" : "Download Data Table" }}
                </button>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0" v-if="tableView === 'PROFILES'">

            <!-- Container for Menu & Content -->
            <div id="container" style="height: 79vh!important; overflow: hidden;"
              class="content-container sidebar-padding-show">
              <!-- Sidebar Menu -->
              <div id="sidebar" class="sidebar">
                <button id="btnFilter" @click="toggleMenu" class="vertical-button open fade-btn"><span id="chevron"
                    class="chevron-left"></span></button>
                <div style="background-color: #EBEDEF ;  border-radius: 5px;">
                  <!--<div class="col-12 mb-3">
                  <NSelect v-model:value="LAST_SELECTED_PACKAGE_TO_VIEW" :options="PARENT_SUBSCRIPTION_PACKAGES_OPTS"
                    filterable multiple clearable :placeholder="t('ProximaList.profilesTable.packagesPlaceholder')
                      " :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>-->
                  <div class="tabs">
                    <div id="tab1" style="padding-left: 42px;margin-left:-5px" class="tab active" @click="openTab(1)">
                      Filters</div>
                    <div id="tab2" style="padding-left: 25px;margin-right:-5px" class="tab" @click="openTab(2)">Map
                      Layout</div>
                  </div>
                  <div id="content1" class="tab-content active">
                    <div class="col-12 mb-3">
                      <NSelect v-model:value="LAST_SELECTED_ELEMENT_TO_VIEW"
                        :options="sortBy(SUBSCRIPTION_ELEMENTS_OPTS, 'label')" multiple filterable clearable
                        :placeholder="t('ProximaList.profilesTable.elementsPlaceholder')
                          " :disabled="inProgress" :input-props="{ autocomplete: Math.random() }" />
                    </div>
                    <div class="col-12 mb-3">
                      <NSelect v-model:value="COUNTRY_TO_VIEW" :options="ASSET_COUNTRIES_OPTS" filterable clearable
                        multiple :placeholder="t('ProximaList.profilesTable.countriesPlaceholder')
                          " :disabled="inProgress" :input-props="{ autocomplete: Math.random() }" />
                    </div>
                    <div class="col-12 mb-3">
                      <NSelect v-model:value="COMPANY_TO_VIEW" :options="ASSET_COMPANIES_OPTS" filterable clearable
                        multiple :placeholder="t('ProximaList.profilesTable.companiesPlaceholder')
                          " :disabled="inProgress" :input-props="{ autocomplete: Math.random() }" />
                    </div>
                    <div class="col-12 mb-3">
                      <NSelect v-model:value="ASSET_TO_VIEW" :options="ASSET_ASSETS_OPTS" filterable clearable multiple
                        :placeholder="t('ProximaList.profilesTable.assetsPlaceholder')
                          " :disabled="inProgress" :input-props="{ autocomplete: Math.random() }" />
                    </div>
                    <div class="col-12 mb-3">
                      <NSelect v-model:value="STATUS_TO_VIEW" :options="ASSET_STAGES_OPTS" filterable clearable multiple
                        :placeholder="t('ProximaList.profilesTable.statusPlaceholder')
                          " :disabled="inProgress" :input-props="{ autocomplete: Math.random() }" />
                    </div>
                    <div class="col-12 mb-3">
                      <NSelect v-model:value="TYPE_TO_VIEW" :options="ASSET_TYPES_OPTS" filterable clearable multiple
                        :placeholder="t('ProximaList.profilesTable.typesPlaceholder')
                          " :disabled="inProgress" :input-props="{ autocomplete: Math.random() }" />
                    </div>
                    <div class="col-12 mb-3">
                      <NSelect v-model:value="PRODUCT_GROUP_TO_VIEW" :options="ASSET_PRODUCT_GROUP_OPTS" filterable
                        clearable multiple :placeholder="t('ProximaList.profilesTable.productGroupPlaceholder')
                          " :disabled="inProgress" :input-props="{ autocomplete: Math.random() }" />
                    </div>
                    <div class="col-12 mb-3">
                      <NSelect v-model:value="PRODUCT_TO_VIEW" :options="ASSET_PRODUCT_OPTS" filterable clearable
                        multiple :placeholder="t('ProximaList.profilesTable.productsPlaceholder')
                          " :disabled="inProgress" :input-props="{ autocomplete: Math.random() }" />
                    </div>
                    <div style="height:35px!important;" class="col-12">
                      <div class="row">
                        <div class="col-6 mb-3">
                          <button class="button" :disabled="inProgress" @click="getProfiles">
                            Redraw
                          </button>
                        </div>
                        <div class="col-6 mb-3">
                          <button class="button" :disabled="inProgress" @click="resetFilters">
                            {{ $t("ProximaList.profilesTable.clear") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="content2" class="tab-content">
                    <h5>Status:</h5>
                    <label class="custom-checkbox">
                      <input type="checkbox" name="status" v-model="isProject" value="project">
                      <span class="checkmark project"></span>
                      Project
                    </label>
                    <br />
                    <label class="custom-checkbox">
                      <input type="checkbox" name="status" v-model="isOperating" value="operating">
                      <span class="checkmark operating"></span>
                      Operating
                    </label>
                    <br />
                    <label class="custom-checkbox">
                      <input type="checkbox" name="status" v-model="isClosed" value="closed">
                      <span class="checkmark closed"></span>
                      Closed/C&M
                    </label>
                    <h5>Type:</h5>
                    <label class="custom-checkbox">
                      <input type="checkbox" name="status" v-model="isMine" value="mine">
                      <span class="checkmark-mine"></span>
                      Mine & Beneficiation
                    </label>
                    <br />
                    <label class="custom-checkbox project">
                      <input type="checkbox" name="status" v-model="isRefinery" value="refinery">
                      <span class="checkmark-refinery"></span>
                      Refinery & Factory
                    </label>

                    <h5>Asset Group Colours:</h5>
                    <div class="row" v-for="element in LAST_SELECTED_ELEMENT_TO_VIEW" :key="element">
                      <div class="col-6">{{SUBSCRIPTION_ELEMENTS_OPTS.find(x => x.value == element)?.label ?? element
                      }}</div>
                      <div class="col-6">
                        <input style="width: 50px; border-radius: 0px; padding: 0px; border: 1px solid lightgray;"
                          type="color" :value="getColor(element)" @input="updateColor(element, $event.target.value)" />
                      </div>
                    </div>
                    <div style="margin-bottom: -12px;" class="row">
                      <div style="height:30px!important;" class="col-6 mb-3">
                        <button class="button" :disabled="inProgress" @click="checkStatus">
                          Update
                        </button>
                      </div>
                      <div style="height:30px!important;" class="col-6 mb-3">
                        <button class="button" :disabled="inProgress" @click="resetForm">
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row"
                    style="margin-top: 3px; padding-top: 5px; float: left; border-radius: 5px; margin-left:0px; width:280px; overflow: auto;">
                    <div v-if="isProject" style="display: flex;">
                      <div class="checkmark-box gold"></div>
                      <div>Project</div>
                    </div>
                    <div v-if="isOperating" style="display: flex;">
                      <div class="checkmark-box blue"></div>
                      <div>Operating</div>
                    </div>
                    <div v-if="isClosed" style="display: flex;">
                      <div class="checkmark-box red"></div>
                      <div>Closed/C&M</div>
                    </div>
                    <div v-if="isMine" style="display: flex;">
                      <div class="square"></div>
                      <div style="margin-left: 5px;">Mine & Beneficiation</div>
                    </div>
                    <div v-if="isRefinery" style="display: flex;">
                      <div class="triangle"></div>
                      <div style="margin-left: 5px;"> Refinery & Factory</div>
                    </div>
                    <div v-if="!isOperating && !isProject && !isClosed">
                      <div style="display: flex;" v-for="element in LAST_SELECTED_ELEMENT_TO_VIEW" :key="element">
                        <div
                          style="margin-top: 5px; margin-right: 5px; height: 15px; width: 20px; border: 1px solid lightgray;"
                          :style="{ backgroundColor: getColor(element) ?? 'white' }"></div>
                        <div>{{SUBSCRIPTION_ELEMENTS_OPTS.find(x => x.value == element)?.label ?? element
                        }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Content Area -->
              <div class="content">
                <!--opted for style and not v-if so that the map doesn't reload after changing from table to map again. -->
                <div :style="showMap == true ? 'display:block' : 'display:none'">
                  <div style="height:58vw">
                    <div style="
                        height: 100%;
                        width: 100%;
                        position: relative;
                        overflow: hidden;
                      " id="drawer-target">
                      <div v-if="inProgress" class="loading-overlay">
                        <NProgress style="margin: 8px 8px;" type="circle"
                          :percentage="publishedProfilesRequestPercentage" :color="'#192e40'"
                          :rail-color="changeColor('#192e40', { alpha: 0.2 })" :indicator-text-color="'#192e40'" />
                      </div>
                      <div style="height: 100%; width: 100%">
                        <iframe id="interactiveMapProxima" :src="`${envs('MAP_BASE')}${getMapStyle()}?version=241`"
                          frameborder="0" style="width: 66vw; height: 100vh;"></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <NDataTable :style="showMap != true ? 'display:block' : 'display:none'" id="info-table"
                  :columns="columns" :data="inProgress ? [] : sortedData" :max-height="'700px'"
                  @update:sorter="handleSort">
                  <template #empty>
                    <div v-if="inProgress" class="loading-overlay">
                      <NProgress type="circle" :percentage="publishedProfilesRequestPercentage" :color="'#192e40'"
                        :rail-color="changeColor('#192e40', { alpha: 0.2 })" :indicator-text-color="'#192e40'" />
                    </div>
                    <p v-else>No data available</p>
                  </template>
                </NDataTable>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <NDrawer v-model:show="showSupplyChainAgreementInfo" :width="400" placement="right">
      <NDrawerContent>
        <h6 class="text-capitalize">
          {{ mountedSupplyChainAgreement?.full?.title }}
        </h6>

        <br />
        <div v-if="mountedSupplyChainAgreement?.full?.type">
          Type:&nbsp;{{ mountedSupplyChainAgreement?.full?.type }}
        </div>
        <div v-if="mountedSupplyChainAgreement?.full?.product">
          Product:&nbsp;{{ mountedSupplyChainAgreement?.full?.product }}
        </div>
        <div v-if="capacity">Volumes:&nbsp; {{ capacity }}</div>
        <div v-if="mountedSupplyChainAgreement?.full?.announcedDate">
          Date Announced:&nbsp;{{
            mountedSupplyChainAgreement?.full?.announcedDate
          }}
        </div>
        <div v-if="mountedSupplyChainAgreement?.full?.announcedStartDate">
          Start Date:&nbsp;
          {{ mountedSupplyChainAgreement?.full?.announcedStartDate }}
        </div>
        <div v-if="mountedSupplyChainAgreement?.full?.endDate">
          End Date: &nbsp;{{ mountedSupplyChainAgreement?.full?.endDate }}
        </div>

        <br v-if="suppliers.length" />
        <h6 v-if="suppliers.length">Suppliers</h6>
        <div style="display: block; width: 100%" v-for="nth in suppliers" :key="nth">
          <div>Company:&nbsp;{{ nth?.companyName }}</div>
          <div>
            Asset:&nbsp;
            <span style="cursor: pointer" @click="goToAssetProfile(nth)">{{
              nth?.asset
            }}</span>
          </div>
        </div>

        <br v-if="receivers.length" />
        <h6 v-if="receivers.length">Receivers</h6>
        <div style="display: block; width: 100%" v-for="nth in receivers" :key="nth">
          <div>Company:&nbsp;{{ nth?.companyName }}</div>
          <div>
            Asset:&nbsp;
            <span style="cursor: pointer" @click="goToAssetProfile(nth)">{{
              nth?.asset
            }}</span>
          </div>
        </div>

        <br />
        <h6>Deal Overview</h6>
        <p>
          {{ mountedSupplyChainAgreement?.full?.dealProfile }}
        </p>
      </NDrawerContent>
    </NDrawer>

    <NDrawer v-model:show="showMarkerInfo" :width="400" :height="200" placement="right">
      <NDrawerContent>
        <div class="row gap-y-2" v-if="PROFILE_TO_VIEW_IN_DRAWER.type === 'ASSET_PROFILE'">
          <div class="col-12">
            <button class="btn btn-xs proxima-blue-bg-gradient bg-gradient-success text-white px-2" style="float: right"
              :disabled="inProgress" @click="
                goToAssetProfile({ assetUUID: PROFILE_TO_VIEW_IN_DRAWER?.uuid })
                ">
              Go to profile
            </button>
          </div>
          <div class="col-12">
            <h5>{{ PROFILE_TO_VIEW_IN_DRAWER.title }}</h5>
            <br />
          </div>
          <div class="col-12" v-if="PROFILE_TO_VIEW_IN_DRAWER.profile">
            <h6>Profile</h6>
            <p>{{ PROFILE_TO_VIEW_IN_DRAWER.profile }}</p>
          </div>
          <div class="col-12" v-if="PROFILE_TO_VIEW_IN_DRAWER.project">
            <h6>Project</h6>
            <p>{{ PROFILE_TO_VIEW_IN_DRAWER.project }}</p>
          </div>
        </div>
        <div class="row gap-y-2" v-else-if="PROFILE_TO_VIEW_IN_DRAWER.type === 'COMPANY_PROFILE'">
          <div class="col-12">
            <button class="btn btn-xs proxima-blue-bg-gradient bg-gradient-success text-white px-2" style="float: right"
              :disabled="inProgress" @click="goToCompanyProfile(PROFILE_TO_VIEW_IN_DRAWER?.uuid)">
              Go to profile
            </button>
          </div>
          <div class="col-12">
            <h5>{{ PROFILE_TO_VIEW_IN_DRAWER.title }}</h5>
            <br />
          </div>
          <div class="col-12" v-if="PROFILE_TO_VIEW_IN_DRAWER.overview">
            <h6>Overview</h6>
            <p>{{ PROFILE_TO_VIEW_IN_DRAWER.overview }}</p>
          </div>
          <div class="col-12" v-if="PROFILE_TO_VIEW_IN_DRAWER.profile">
            <h6>Profile</h6>
            <p>{{ PROFILE_TO_VIEW_IN_DRAWER.profile }}</p>
          </div>
        </div>
      </NDrawerContent>
    </NDrawer>
    <NDrawer v-model:show="showFilters" :width="400" :height="200" placement="right">
      <NDrawerContent>

      </NDrawerContent>
    </NDrawer>
  </div>
  <div id="confirmationModal" class="modal">
    <div class="modal-content">
      <p>You have applied an asset status filter as well as asset group colours. Only one of these filters may be
        applied
        at a time.</p>
      <div class="modal-buttons">
        <button class="confirm-btn" @click="submitForm(true)">Retain Asset Group Colours</button>
        <button class="cancel-btn" @click="submitForm(false)">Apply Status Filter</button>
        <button class="cancel-btn" @click="closeModal()">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProximaProfiles",
};

</script>
<script setup>
import { computed, h, nextTick, onBeforeMount, onMounted, ref, watch } from "vue";
import {
  NDataTable,
  NSelect,
  NDrawer,
  NDrawerContent,
  NDropdown,
  NProgress
} from "naive-ui";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { ROUTES } from "../../routes/names";
import { LAST_SELECTED_ELEMENT_TO_VIEW, LAST_SELECTED_PACKAGE_TO_VIEW, COUNTRY_TO_VIEW, COMPANY_TO_VIEW, ASSET_TO_VIEW, STATUS_TO_VIEW, TYPE_TO_VIEW, PRODUCT_GROUP_TO_VIEW, PRODUCT_TO_VIEW } from "../../composables/generics";
import {
  PROFILES_FILTERS,
  PUBLISHED_PROFILES,
  PUBLISHED_ALL_PROFILES,
  getPublishedProfiles,
  getAllPublishedProfiles,
  inProgress,
  publishedProfilesRequestPercentage,
  changeColor,
  ASSETS_OPTS,
  COUNTRIES_OPTS,
  COMPANIES_OPTS,
  TYPES_OPTS,
  STAGES_OPTS,
  PRODUCT_OPTS
} from "../../composables/profiles/list";
import {
  MOUNTED_ASSET_PROFILES,
  mountProfile as _mountAssetProfile,
  getAssetProfiles,
  PROFILE_ID,
  ASSET_STATUS_OPTS,
  ASSET_COUNTRIES_OPTS,
  ASSET_COMPANIES_OPTS,
  ASSET_ASSETS_OPTS,
  ASSET_STAGES_OPTS,
  ASSET_TYPES_OPTS,
  ASSET_PRODUCT_GROUP_OPTS,
  ASSET_PRODUCT_OPTS
} from "../../composables/profiles/asset";
import {
  MOUNTED_COMPANY_PROFILE,
  mountProfile as _mountCompanyProfile,
  getCompanies,
  getCompanyProfiles,
  ALL_COMPANIES_OPTS,
  getCompanyProfile,
} from "../../composables/profiles/company";
import {
  mountProfile as _mountCountryProfile,
  getCountryProfiles,
  COUNTRY_COMMODITIES,
  MOUNTED_COUNTRY_PROFILES,
  getCountryCommodities,
  COUNTRY_ID
} from "../../composables/profiles/country";
import { PRODUCT_GROUP_OPTS, MARKET_SERVICES_OPTS } from "../../utils/constants";
import TableHeader from "../../components/ProfileTable/TableHeader";
import TableCell from "../../components/ProfileTable/TableCell";
import TableCellType from "../../components/ProfileTable/TableCellType";
import TableCellSignificance from "../../components/ProfileTable/TableCellSignificance";
import TableCellStatus from "../../components/ProfileTable/TableCellStatus";
import TableCellProducts from "../../components/ProfileTable/TableCellProducts";
import CompanyProfile from "../../components/Profiles/CompanyProfile.vue"
import CountryProfile from "../../components/Profiles/CountryProfile.vue"
import ProfilesMenu from "../../components/ProfilesMenu.vue";
import { envs, log } from "../../utils";
import SummaryCard from "../../components/dumb/SummaryCard.vue";
import SelectElement from "./SelectElement.vue";
import { SUBSCRIPTION_INFO } from "../../composables/subscription";
import { sortBy, uniqBy } from "lodash";
import {
  SUBSCRIPTION_ELEMENTS_OPTS, SUBSCRIPTION_PACKAGES_OPTS, PARENT_SUBSCRIPTION_PACKAGES_OPTS
} from "../../composables/subscription";
import LatestNews from '../../components/LatestNews.vue'
import {
  tableData,
  sortTableColumn,
} from "../../composables/tableColumnSort.js";
import HttpClient from "project-blue-http-client";
import { AUTHENTICATION_URL } from "../../utils/constants.js";
import { getSubscriptionInfo } from "../../composables/subscription";
import * as XLSX from "xlsx";
import { MAP_ELEMENT_OPTS } from "../../composables/maps.js";
import { type } from "jquery";

const sortState = ref({
  columnKey: "country",
  order: "ascend",
});

const assetsPlotted = ref(0);
const colors = ref([])
const isProject = ref(false);
const isOperating = ref(false);
const isClosed = ref(false);
const isMine = ref(false);
const isRefinery = ref(false);
const mapStyle = ref(null);

const filterByStatus = ref(false);
const filterByType = ref(false);
const statusFilters = ref("ALL");
const typeFilters = ref([]);
let legendMenu = ref();
const selectedLegendOpts = ref("ALL");

const MAP_OPTS = [
  {
    label: "Equi-Rectangular",
    key: "equirectangular",
  },
  {
    label: "Mercator",
    key: "mercator",
  },
  {
    label: "Globe",
    key: "globe",
  },
  {
    label: "Equal Earth",
    key: "equalEarth",
  }
];
const MAP_STYLE_OPTS = [
  {
    label: "Street View",
    key: "Street_View",
  },
  {
    label: "Outdoor",
    key: "Outdoor",
  },
  {
    label: "Light",
    key: "Light",
  },
  {
    label: "Dark",
    key: "Dark",
  },
  {
    label: "Satellite",
    key: "Satellite",
  },
  {
    label: "Satellite Street View",
    key: "Satellite_And_Street_View",
  }
];
const LEGEND_OPTS = [
  {
    label: "All",
    key: "ALL",
  },
  {
    label: "Type",
    key: "TYPE",
  },
  {
    label: "Status",
    key: "STATUS",
  },
];


const openModal = () => {
  document.getElementById("confirmationModal").style.display = "flex";
}

const closeModal = () => {
  document.getElementById("confirmationModal").style.display = "none";
}

const resetForm = () => {

  isProject.value = false;
  isOperating.value = false;
  isClosed.value = false;
  isMine.value = false;
  isRefinery.value = false;
  submitForm(false);
}

const submitForm = (retainColors) => {

  let _statusFilters = [];
  let _typeFilters = [];
  if (isMine.value) {
    _typeFilters.push("Mine");
  }
  if (isRefinery.value) {
    _typeFilters.push("Refinery");
  }
  typeFilters.value = _typeFilters;

  if (isOperating.value) {
    _statusFilters.push("Operating");
  }
  if (isClosed.value) {
    _statusFilters.push("Closed");
    _statusFilters.push("Idle");
    _statusFilters.push("Unknown");
  }
  if (isProject.value) {
    _statusFilters.push("Project");
  }
  statusFilters.value = _statusFilters;
  filterByStatus.value = _statusFilters.length > 0;
  filterByType.value = _typeFilters.length > 0;
  if (!retainColors) {
    colors.value = []
  }
  else if (colors.value.length > 0) {
    isProject.value = false;
    isOperating.value = false;
    isClosed.value = false;
  }
  updateMapMarkers();
  document.getElementById("confirmationModal").style.display = "none";
}


const getColor = (element) => {
  const found = colors.value.find(c => c.code === element);
  let mapStyle = getMapStyle().replace(".html", "");
  return found ? found.color : mapStyle == "Light" || mapStyle == "Outdoor" || mapStyle == "Street_View" ? "#192E40" : '#FFFFFF'; // Default white if not found
};

const updateColor = (element, newColor) => {
  let name = SUBSCRIPTION_ELEMENTS_OPTS.value.find(x => x.value == element)?.label ?? element;
  let found = colors.value.find(c => c.code === element);
  if (found) {
    found.color = newColor; // Update existing color
  } else {
    colors.value.push({ code: element, color: newColor, name: name }); // Add new color entry
  }
}
const toggleMenu = () => {
  document.getElementById("container").classList.toggle("collapsed");
  let sidebar = document.getElementById("sidebar");
  let filter = document.getElementById("btnFilter");
  let chevron = document.getElementById("chevron");

  let map = document.getElementById("interactiveMapProxima");

  if (sidebar.classList.contains("sidebar-padding-hide")) {
    sidebar.classList.add("sidebar-padding-show");
    sidebar.classList.remove("sidebar-padding-hide");
    filter.classList.remove("closed");
    filter.classList.add("open");
    chevron.classList.add("chevron-left");
    chevron.classList.remove("chevron-right");
    map.contentWindow.postMessage("Hide_Legend", "*");
  }
  else {
    sidebar.classList.remove("sidebar-padding-show");
    sidebar.classList.add("sidebar-padding-hide");
    filter.classList.remove("open");
    filter.classList.add("closed");
    chevron.classList.add("chevron-right");
    chevron.classList.remove("chevron-left");
    map.contentWindow.postMessage("Show_Legend", "*");
  }
}

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const mapView = ref(false);
const tableView = ref("PROFILES"); // TODO: needs improvements -> ex: PROFILES \ SUPPLY_CHAIN_AGREEMENTS
const showSupplyChainAgreementInfo = ref(false);
const showMarkerInfo = ref(false);
const PROFILE_TO_VIEW_IN_DRAWER = ref();
const grayScale = ref(true);
// const selectRef = ref(null);
const COMMODITY_ID = ref(null);
// const STATUS_ID = ref(null);
const PROFILE_TO_VIEW_DETAILS = ref(null);
const COMMODITY_OVERVIEW_DETAILS = ref(null);
let byProductCommodities = ref([]);
const agreementTypesFilter = ref([]);
const companyFilter = ref([]);
const productFilter = ref([]);
const companyProfileData = ref(null);
const countryProfileData = ref(null);
const showMap = ref(true);
const showFilters = ref(false);
const userMapColors = ref({
  mine: "",
  factory: "",
  closed: "",
  project: "",
  operating: ""
});

// let primaryCommodities = ref([]);
// const isRed = (index) => index >= byProductCommodities.length;

const columns = [
  {
    key: "country",
    defaultSortOrder: "ascend",
    // sorter: "default",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.countryHeader"),
      });
    },
    render: (row) => {
      return h(TableCell, {
        text: row.country,
        hasProfile: row.hasCountryProfile,
        profile: row.fullCountryProfile,
        onMountProfile: mountCountryProfile,
      });
    },
  },
  {
    key: "company",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.companyHeader"),
      });
    },
    render: (row) => {
      return h(TableCell, {
        text: row.company,
        hasProfile: row.hasCompanyProfile,
        profile: row.fullCompanyProfile,
        onMountProfile: mountCompanyProfile,
      });
    },
  },
  {
    key: "asset",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.assetHeader"),
      });
    },
    render: (row) => {
      return h(TableCell, {
        text: row.asset,
        hasProfile: true,
        profile: row.fullAssetProfile,
        onMountProfile: mountAssetProfile,
      });
    },
  },
  {
    key: "significance",
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.significanceHeader"),
      });
    },
    render: (row) => {
      return h(TableCellSignificance, {
        text: row.significance,
      });
    },
  },
  {
    key: "status",
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.statusHeader"),
      });
    },
    render: (row) => {
      return h(TableCellStatus, {
        text: row.status,
      });
    },
  },
  {
    key: "type",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.typeHeader"),
      });
    },
    render: (row) => {
      return h(TableCellType, {
        text: row.type,
      });
    },
  },
  {
    key: "productNames",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.productsHeader"),
      });
    },
    render: (row) => {
      return row.productNames;
    },
  },
];

const data = computed(() => {
  const countryMap = {};
  for (const nth of PUBLISHED_PROFILES.value?.countryProfiles || []) {
    countryMap[nth.countryCode] = nth;
  }

  const companyMap = {};
  const associatedCompanyMap = {};
  for (const nth of PUBLISHED_PROFILES.value?.companyProfiles || []) {
    companyMap[nth.companyId] = nth;
    for (const ith of nth.associatedCompanies || []) {
      associatedCompanyMap[ith] = nth;
    }
  }

  return (PUBLISHED_PROFILES.value?.assetProfiles || []).map((profile) => {
    const hasCompanyProfile =
      profile.companyId in companyMap ||
      profile.companyId in associatedCompanyMap;
    const companyProfile =
      companyMap?.[profile.companyId] ||
      associatedCompanyMap?.[profile.companyId];
    const countryProfile = countryMap?.[profile.countryCode];
    return {
      uuid: profile.uuid,
      hasCountryProfile: profile.countryCode in countryMap,
      countryProfile: countryProfile?.uuid,
      country: profile.countryName,
      hasCompanyProfile,
      companyProfile: companyProfile?.uuid,
      company: profile.companyName,
      significance: profile.significance,
      asset: profile.short_Name,
      type: profile.type,
      status: profile.status,
      products: profile.products,
      fullAssetProfile: profile,
      fullCompanyProfile: companyProfile,
      fullCountryProfile: countryProfile,
      productNames: profile.productNames,
    };
  });
});

const mountedSupplyChainAgreement = ref(null);
const supplyChainAgreementsColumns = [
  {
    key: "dateAnnounced",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.dateAnnouncedHeader"),
      });
    },
    render: (row) => {
      return row.dateAnnounced;
    },
  },
  {
    key: "agreementType",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.agreementTypeHeader"),
      });
    },
    render: (row) => {
      return row.agreementType;
    },
  },
  {
    key: "supplyCompany",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.supplyCompanyHeader"),
      });
    },
    render: (row) => {
      return row.supplyCompany;
    },
  },
  {
    key: "partnerCompany",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.partnerCompanyHeader"),
      });
    },
    render: (row) => {
      return row.partnerCompany;
    },
  },
  {
    key: "products",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.productsHeader"),
      });
    },
    render: (row) => {
      return row.products;
    },
  },
  {
    key: "volumes",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.volumesHeader"),
      });
    },
    render: (row) => {
      return row.volumes;
    },
  },
  {
    key: "startDate",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.startDateHeader"),
      });
    },
    render: (row) => {
      return row.startDate;
    },
  },
  {
    key: "endDate",
    sorter: true,
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.endDateHeader"),
      });
    },
    render: (row) => {
      return row.endDate;
    },
  },
];

const mappedSupplyChainAgreementsData = computed(() => {
  return (PUBLISHED_PROFILES.value?.dealAndContractProfiles || []).map(
    (profile) => {
      return {
        uuid: profile.uuid,
        dateAnnounced: profile.announcedDate,
        agreementType: profile.type,
        supplyCompany: (profile.suppliersAndReceivers || [])
          .filter((nth) => nth.partyType === "SUPPLIER")
          .map((nth) => nth.companyName)
          .join(", "),
        partnerCompany: (profile.suppliersAndReceivers || [])
          .filter((nth) => nth.partyType === "RECEIVER")
          .map((nth) => nth.companyName)
          .join(", "),
        products: profile.product,
        volumes:
          profile.unit && profile.capacity
            ? `${profile.capacity} ${profile.unit}`
            : "",
        startDate: profile.announcedStartDate || profile.actualStartDate,
        endDate: profile.endDate,
        full: profile,
      };
    }
  )
})

const supplyChainAgreementsData = computed(() => {

  if (agreementTypesFilter.value.length || companyFilter.value.length || productFilter.value.length) {
    return mappedSupplyChainAgreementsData.value.filter(r =>
      (agreementTypesFilter.value.length === 0 || agreementTypesFilter.value.includes(r.agreementType)) &&
      (companyFilter.value.length === 0 || companyFilter.value.includes(r.supplyCompany) || companyFilter.value.includes(r.partnerCompany)) &&
      (productFilter.value.length === 0 || productFilter.value.includes(r.products))
    );
  } else {
    return mappedSupplyChainAgreementsData.value;
  }
});

const suppliers = computed(() => {
  return (
    mountedSupplyChainAgreement.value?.full?.suppliersAndReceivers || []
  ).filter((nth) => nth.partyType === "SUPPLIER");
});
const receivers = computed(() => {
  return (
    mountedSupplyChainAgreement.value?.full?.suppliersAndReceivers || []
  ).filter((nth) => nth.partyType === "RECEIVER");
});
const capacity = computed(() => {
  return mountedSupplyChainAgreement.value?.full.capacity &&
    mountedSupplyChainAgreement.value?.full.unit
    ? `${mountedSupplyChainAgreement.value?.full.capacity} ${mountedSupplyChainAgreement.value?.full.unit}`
    : "";
});

const rowProps = (row) => {
  return {
    style: "cursor: pointer;",
    onClick: () => {
      showSupplyChainAgreementInfo.value = true;
      mountedSupplyChainAgreement.value = row;
    },
  };
};

const mountAssetProfile = (profile) => {
  _mountAssetProfile(router, {
    profile,
    element: LAST_SELECTED_ELEMENT_TO_VIEW.value,
  });
};

const mountCountryProfile = (profile) => {
  // _mountCountryProfile(router, {
  //   profile,
  //   element: LAST_SELECTED_ELEMENT_TO_VIEW.value,
  // });

  tableView.value = 'COUNTRY_PROFILES';
  if (LAST_SELECTED_ELEMENT_TO_VIEW.value) {
    profile.selectedElement = LAST_SELECTED_ELEMENT_TO_VIEW.value
  }
  countryProfileData.value = profile;

};

const mountCompanyProfile = async (profile) => {
  // _mountCompanyProfile(router, {
  //   profile,
  //   element: LAST_SELECTED_ELEMENT_TO_VIEW.value,
  // });

  let data = {
    uuid: profile.uuid,
    selectedElement: LAST_SELECTED_ELEMENT_TO_VIEW.value
  }
  await showCompanyProfile(data)

};

const COMPANY_OPTS = computed(() => {
  return ALL_COMPANIES_OPTS.value || []
});

// const companyName = computed(() => {
//   return COMPANY_OPTS.value.filter(r => r.value === PROFILE_ID.value).map(r => r.label)[0]
// })

// const companyProfile = computed(() => {
//   return MOUNTED_COMPANY_PROFILE.value || {}
// })

const assetProfiles = computed(() => {
  return PUBLISHED_PROFILES.value?.assetProfiles || [];
})

const countryProfiles = computed(() => {
  return MOUNTED_COUNTRY_PROFILES.value?.profiles || [];

})

const isSubscribedToAssetProfile = (nth) => {
  const element = LAST_SELECTED_ELEMENT_TO_VIEW.value;
  const allElements = [
    ...new Set([
      ...nth.byProductCommodities || [],
      ...nth.nonCommercialCommodities || [],
      ...nth.primaryCommodities || [],
    ]),
  ];
  return (
    allElements.includes(element) &&
    SUBSCRIPTION_INFO.elements?.map((nth) => nth.code).includes(element)
  );
};

const goToList = () => {
  router.push({ name: ROUTES.PROXIMA_PROFILES.name });
};

const openTab = (id) => {
  let tabs = document.querySelectorAll('.tab');
  let contents = document.querySelectorAll('.tab-content');

  tabs.forEach(tab => tab.classList.remove('active'));
  contents.forEach(content => content.classList.remove('active'));

  document.getElementById(`tab${id}`).classList.add('active');
  document.getElementById(`content${id}`).classList.add('active');
}

const getProfiles = async () => {
  if (LAST_SELECTED_ELEMENT_TO_VIEW.value.length == 0) {
    window.$message.error("Please select at least one item from the asset group before searching");
    return;
  }
  showFilters.value = false;
  await getPublishedProfiles(LAST_SELECTED_ELEMENT_TO_VIEW.value);
};

const resetFilters = async () => {
  ASSET_TO_VIEW.value = null;
  COMPANY_TO_VIEW.value = null;
  COUNTRY_TO_VIEW.value = null;
  STATUS_TO_VIEW.value = null;
  TYPE_TO_VIEW.value = null;
  PRODUCT_GROUP_TO_VIEW.value = null;
  PRODUCT_TO_VIEW.value = null;
  setTimeout(() => {
    nextTick(() => {
      const maps = [
        document.getElementById("interactiveMapProxima"),
      ];
      for (const map of maps) {
        if (map) {
          map.src = map.src.split("&")?.[0] + "&_=" + Math.random();
        }
      }
    });
  }, 500);
  await getPublishedProfiles(LAST_SELECTED_ELEMENT_TO_VIEW.value);
};

watch(PUBLISHED_PROFILES, () => {
  legendMenu.value = "ALL";
  updateMapMarkers();
});


// watch(PROFILE_ID, async () => {
//   if (!PROFILE_ID.value) {
//     PROFILE_TO_VIEW_DETAILS.value = null
//   } else {
//     COMMODITY_ID.value = null
//     COMMODITY_OVERVIEW_DETAILS.value = null
//     // await getCompanyProfiles({ id: PROFILE_ID.value, country: null, commodity: null, keyword: null })
//   }
// });

// watch(COUNTRY_ID, async () => {
//   if (!COUNTRY_ID.value) {
//     PROFILE_TO_VIEW_DETAILS.value = null
//   } else {
//     COMMODITY_ID.value = null
//     COMMODITY_OVERVIEW_DETAILS.value = null
//     await getCountryProfiles(COUNTRY_ID.value)
//   }
// });

const updateMapType = (projection) => {

  let map = document.getElementById("interactiveMapProxima");
  map.contentWindow.postMessage(
    {
      reset: false,
      projection: projection
    },
    "*"
  );
  updateMapMarkers()
}
const updateMapStyle = (style) => {

  window.$message.info("In order to change the map style, we'll need to reload the map with the new style. Don't worry, we'll save the last style that you use and keep that as your default.",
    { duration: 10000 }
  );
  localStorage.setItem("map_style", style);
  let map = document.getElementById("interactiveMapProxima");
  map.src = envs('MAP_BASE') + getMapStyle();
  updateMapMarkers();
}

const getMapStyle = () => {
  let map = (localStorage.getItem("map_style") ?? "Outdoor") + ".html";
  mapStyle.value = MAP_STYLE_OPTS.find(x => x.key == map)?.label ?? "Outdoor"
  return map;
}

const checkStatus = () => {
  if ((isOperating.value || isProject.value || isClosed.value) && colors?.value?.length > 0) {
    openModal();
  }
  else {
    submitForm(true);
  }
}

const updateMapMarkers = (type_or_status, groups) => {
  try {
    let marks = [];
    if (PUBLISHED_PROFILES.value?.assetProfiles) {
      for (let nth of PUBLISHED_PROFILES.value.assetProfiles || []) {
        let group = null;
        if (type_or_status === "TYPE") {
          group = nth.type;
          colors.value = [];
          filterByType = true;
        }
        else if (type_or_status === "STATUS") {
          group = nth.status;
          colors.value = [];
          filterByStatus = true;
        }
        let elements = getElement(nth.primary_Commodities);
        let element = "";
        if (elements?.length > 0) {
          element = elements[0];
        }
        else {
          elements = getElement(nth.by_Product_Commodities);
          if (elements?.length > 0) {
            element = elements[0];
          }
          else {
            elements = getElement(nth.by_Non_Commercial_Commodities);
            element = elements.length > 0 ? elements[0] : nth.primary_Commodities[0];
          }
        }
        marks.push(
          {
            selectedElement: LAST_SELECTED_ELEMENT_TO_VIEW.value,
            assetCount: PUBLISHED_PROFILES.value?.count,
            type: "Feature",
            properties: {
              description: formatAssetProfilePopup(nth),
              type: nth.type,
              country: nth.countryName,
              group: group ?? element,
              element: element,
              status: nth.status,
            },
            geometry: {
              type: "Point",
              coordinates:
                nth.coordinates
                  ?.split(",")
                  .map((ith) => parseFloat(ith.trim()))
                  .reverse() || [],
            },
          });
      }
    }
    if (PUBLISHED_PROFILES.value?.companyProfiles) {
      // TODO
    }
    if (PUBLISHED_PROFILES.value?.countryProfiles) {
      // TODO
    }

    if (type_or_status && groups) {
      marks = marks.filter((nth) => groups?.includes(nth.properties.group));
    }
    setTimeout(() => {
      nextTick(() => {
        const maps = [
          document.getElementById("interactiveMapProxima"),
        ];
        for (const map of maps) {
          if (map) {
            const dummyMarks = JSON.parse(JSON.stringify(marks))
            map.contentWindow.postMessage(
              {
                reset: false,
                marks: dummyMarks,
                //have to stringify and parse for postmessage to work
                //Uncaught (in promise) DataCloneError: Failed to execute 'postMessage' on 'Window': [object Array] could not be cloned.
                colors: JSON.parse(JSON.stringify(colors.value)),
                filterByStatus: filterByStatus.value,
                filterByType: filterByType.value,
                statusFilters: JSON.parse(JSON.stringify(statusFilters.value)),
                typeFilters: JSON.parse(JSON.stringify(typeFilters.value))
              },
              "*"
            );
            //map.contentWindow.console = console;
          }
        }
      });
    }, 1000);
  }
  catch (err) {
    console.log(err);
  }
};

const getElement = (elements) => {
  let foundElements = elements.filter(x => colors?.value?.some(color => color.code === x));
  return foundElements;
}

const formatProducts = (product) => {
  let grossContained = product?.grossContained;
  const units = [product.capacity, product.unit].filter((nth) => nth !== null);
  return `
    ${product.productName || ""} 
    ${product.commission ? `- ${product.commission}` : ""} 
    ${product.status ? `- ${product.status}` : ""}  
    ${units.length ? ` - ${units.join(" ")}` : ""}
    ${grossContained?.toLowerCase() === "gross weight" ? "" : grossContained || ""}
  `
};

const formatStatusColor = (status) => {
  if (status === "Project") return "#E1B16A";
  else if (status === "Operating") return "#1C9099";
  else if (status === "Idle") return "#D83615";
  else if (status === "Closed") return "#D83615";
  else if (status === "Unknown") return "#BFBFBF";
  return "none";
};

const formatAssetProfilePopup = (nth) => {
  return `
      <div style="width: 100%">
        <div
          style="
            display: flex;
            padding: 10px;
            justify-content: space-between;
            align-items: center;
          "
        >
          <a href="javascript:window.parent.postMessage({ type: 'ASSET_PROFILE', uuid: '${nth.uuid
    }'}, '*');" style="text-decoration: none; font-size: 15px;cursor: pointer; color: #192E40;outline: none !important;" onmouseover="this.style.color='#f1ae31'"
   onmouseout="this.style.color='#192E40'" class="profile-link" id="asset-profile-${nth.uuid
    }">${nth.name || nth.alias}</a>
          <div
            style="
              font-size: 9px;
              background-color: ${formatStatusColor(nth.status)};
              color: white;
              border-radius: 5px;
              padding: 3px 6px;
            "
          >
            ${nth.status || ""}
          </div>
        </div>
        <div
          style="
            background-color: #f2f2f2;
            font-size: 12px;
            padding: 10px;
          "
        >
          <div style="display: block; width: 100%">
            ${nth.owners.length ? "<div><b>• Ownership</b></div>" : ""}
            ${nth.owners
      .map(
        (kth) =>
          `<a href="javascript:window.parent.postMessage({ type: 'COMPANY_PROFILE', id: ${kth.id
          }, asset_uuid: '${nth.uuid
          }'}, '*');" style="text-decoration: none;padding-left: 8px;color: #67748e; cursor: ${companyHasProfile(kth.id) ? "pointer" : "default"
          };" class="profile-link" id="company-profile-${kth.id}">${kth.name || ""
          } (${kth.percentage || ""}%)</a>`
      )
      .join("")}
          </div>
          <div style="display: block; width: 100%">
            <div><b>• Markets</b></div>
            <div style="padding-left: 8px;color: #67748e;">
              Primary:
              ${(nth.primary_Commodities || []).join(", ")}
            </div>
          </div>
          <div style="display: block; width: 100%">
            ${nth.products.length ? "<div><b>• Products</b></div>" : ""}
            ${nth.products
      .map(
        (kth) =>
          `
                <div style="display: block; padding-left: 8px;color: #67748e;">
                  ${kth.productName || ""} - ${kth.capacity || ""} ${kth.unit || ""} ${kth.status ? "(" + kth.status + ")" : ""
          }
                </div>
                `
      )
      .join("")}
          </div>
        </div>
      </div>
    `;
};

const goToAssetProfile = (nth) => {
  if (nth?.assetUUID) {
    router.push({
      name: ROUTES.ASSET_PROFILE.name,
      params: { id: nth?.assetUUID },
      query: { element: LAST_SELECTED_ELEMENT_TO_VIEW.value },
    });
  }
};

const goToCompanyProfile = (companyUUID) => {
  if (companyUUID) {
    router.push({
      name: ROUTES.COMPANY_PROFILE.name,
      params: { id: companyUUID },
      query: { element: LAST_SELECTED_ELEMENT_TO_VIEW.value },
    });
  }
};

const scrollToTable = () => {
  const el = document.getElementById("info-table");
  el.scrollIntoView();
};

// Note: instead of watching mapview -> listen to FRAME_LOADED signal from iframe instead
// watch(mapView, () => {
//   updateMapMarkers();
// });
window.addEventListener("message", (event) => {
  if (event.data?.type === "ASSET_PROFILE") {
    const assetProfile = (PUBLISHED_PROFILES.value?.assetProfiles || [])?.find(
      (nth) => nth.uuid === event.data.uuid
    );
    PROFILE_TO_VIEW_IN_DRAWER.value = {
      type: event.data.type,
      uuid: assetProfile.uuid,
      title: assetProfile.name || assetProfile.alias,
      profile: assetProfile.profile,
      project: assetProfile.projects,
    };
    showMarkerInfo.value = true;
  }
  else if (event.data?.type === "COMPANY_PROFILE") {
    const owner = (PUBLISHED_PROFILES.value?.assetProfiles || [])
      ?.find((nth) => nth.uuid === event.data.asset_uuid)
      ?.owners?.find((nth) => nth.id === event.data.id);
    const companyProfile = (
      PUBLISHED_PROFILES.value?.companyProfiles || []
    )?.find((nth) => event.data.id === nth.id);

    if (companyProfile) {
      PROFILE_TO_VIEW_IN_DRAWER.value = {
        type: event.data.type,
        uuid: companyProfile.uuid,
        title: companyProfile.companyName || companyProfile.alias,
        overview: companyProfile.companyOverview,
        profile: companyProfile.profileOverview,
      };
      showMarkerInfo.value = true;
    }
  }
  else if (event.data === "FRAME_LOADED") {
    updateMapMarkers();
  }
  else if (typeof event.data === "number") {
    assetsPlotted.value = event.data;
  }
});

const companyHasProfile = (id) => {
  const companyProfile = (
    PUBLISHED_PROFILES.value?.companyProfiles || []
  )?.find((nth) => id === nth.id);
  return companyProfile ? true : false;
};

const cleanup = () => {
  if (!showMarkerInfo.value) {
    PROFILE_TO_VIEW_IN_DRAWER.value = null;
  }
  PROFILE_ID.value = null;
  COMMODITY_ID.value = null;
  COUNTRY_ID.value = null;
  PROFILE_TO_VIEW_DETAILS.value = null;

  if (route.query.profileType) {
    router.push({ path: route.path, query: { _: Math.random() } });
    COMMODITY_ID.value = null;
    COMMODITY_OVERVIEW_DETAILS.value = null;
  }

};

const saveToPNG = () => {
  setTimeout(() => {
    nextTick(() => {

      if (showMap.value == true) {
        const maps = [
          document.getElementById("interactiveMapProxima"),
        ];
        for (const map of maps) {
          if (map) {
            map.contentWindow.postMessage("DOWNLOAD_MAP", "*");
            map.contentWindow.console = console;
          }
        }
      }
      else {
        if (data.value.length == 0) {
          window.$message.console.warn("No data to download");
          return;
        }
        let formattedData = data.value.map(asset => ({
          "Asset Name": asset.asset,
          "Country Name": asset.country,
          "Company Name": asset.company,
          "Significance": asset.significance ?? "N/A",
          "Status": asset.status,
          "Type": asset.type,
          "Products": asset.products.map(product => product.productName).join(",")
        }));
        let worksheet = XLSX.utils.json_to_sheet(formattedData);
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Proxima Data");
        XLSX.writeFile(workbook, "Proxima Data Extract.xlsx");
      }
    });
  }, 1000);
};

const showTypes = () => {
  legendMenu.value = "TYPE";
  updateMapMarkers("TYPE", [
    "Mine",
    "Recycler",
    "Recycling",
    "Tailings",
    "Tailings/Slag",
    "Factory",
    "Refinery",
  ]);
};

const showStatus = () => {
  legendMenu.value = "STATUS";
  updateMapMarkers("STATUS", [
    "Closed",
    "Idle",
    "Unknown",
    "Project",
    "Operating"
  ]);
};

const showMineAndBeneficiation = () => {
  updateMapMarkers("TYPE", [
    "Mine",
    "Recycler",
    "Recycling",
    "Tailings",
    "Tailings/Slag",
  ]);
};

const showRefineryAndFactory = () => {
  updateMapMarkers("TYPE", ["Factory", "Refinery"]);
};

const showClosed = () => {
  updateMapMarkers("STATUS", ["Closed", "Idle", "Unknown"]);
};

const showProject = () => {
  updateMapMarkers("STATUS", ["Project"]);
};

const showOperating = () => {
  updateMapMarkers("STATUS", ["Operating"]);
};

const showCompanyProfile = (data) => {
  tableView.value = 'COMPANY_PROFILES';
  companyProfileData.value = data;
};

// SUPPLY CHAIN AGREEMENTS FILTERS //
const agreementTypeOpts = computed(() => {
  let opts = mappedSupplyChainAgreementsData.value.map(r => {
    return {
      label: r.agreementType,
      value: r.agreementType
    }
  })
  const sorted = sortBy(opts, "label");
  return uniqBy(sorted, "label");
})

const companyOpts = computed(() => {
  let supplyCompanies = mappedSupplyChainAgreementsData.value.filter(r => r.supplyCompany != '').map(r => {
    return {
      label: r.supplyCompany,
      value: r.supplyCompany
    }
  })

  let partnerCompanies = mappedSupplyChainAgreementsData.value.filter(r => r.partnerCompany != '').map(r => {
    return {
      label: r.partnerCompany,
      value: r.partnerCompany
    }
  })

  let companies = supplyCompanies.concat(partnerCompanies)

  const sorted = sortBy(companies, "label");
  return uniqBy(sorted, "label");
})

const productOpts = computed(() => {
  let opts = mappedSupplyChainAgreementsData.value.map(r => {
    return {
      label: r.products,
      value: r.products
    }
  })
  const sorted = sortBy(opts, "label");
  return uniqBy(sorted, "label");
})

watch(SUBSCRIPTION_ELEMENTS_OPTS, () => {
  if (LAST_SELECTED_ELEMENT_TO_VIEW.value) {
    return;
  }
  LAST_SELECTED_ELEMENT_TO_VIEW.value = SUBSCRIPTION_ELEMENTS_OPTS?.value[0]?.value ? [SUBSCRIPTION_ELEMENTS_OPTS?.value[0]?.value] : [];
  //(LAST_SELECTED_ELEMENT_TO_VIEW.value);
});


// Watch for changes in `formattedData` or fallback data and sync local data
watch(
  () =>
    tableView === 'SUPPLY_CHAIN_AGREEMENTS' ? supplyChainAgreementsData?.value : data?.value,
  (newValue) => {
    tableData.value = [...newValue];
  },
  { immediate: true }
);

const handleSort = (sorter) => {
  if (sorter.order === false) {
    sorter.order = "descend"; // Default to "ascend" if unsorted
  }
  if (sorter.order === "ascend" || sorter.order === "descend") {
    sortState.value = sorter;
  }
};

watch(sortState, (newValue) => {
  if (newValue.order === false) {
    sortState.value.order = "ascend";
  }
});

const sortedData = computed(() => {
  let _data = tableView === 'SUPPLY_CHAIN_AGREEMENTS' ? supplyChainAgreementsData?.value : data?.value;
  if (!sortState.value || !sortState.value.columnKey) return _data;

  return [..._data].sort((a, b) => {
    let aValue = a[sortState.value.columnKey];
    let bValue = b[sortState.value.columnKey];

    // Handle array values (like in the 'profile' column)
    if (Array.isArray(aValue)) aValue = aValue[0];
    if (Array.isArray(bValue)) bValue = bValue[0];

    // Handle date values
    if (sortState.value?.columnKey === "updatedAt") {
      const aDate = aValue ? new Date(aValue) : 0;
      const bDate = bValue ? new Date(bValue) : 0;

      if (aDate === bDate) return 0;

      if (sortState.value.order === "ascend") {
        return aDate - bDate;
      } else {
        return bDate - aDate;
      }
    }

    if (aValue === bValue) return 0;

    if (sortState.value.order === "ascend") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });
});


onMounted(async () => {
  await getSubscriptionInfo();
  if (route.query.element) {
    let list = route.query.element ? [route.query.element] : []
    LAST_SELECTED_ELEMENT_TO_VIEW.value = list;
  }

  /*let client = new HttpClient(AUTHENTICATION_URL);
  await client.get("user/getUserMapColors").then(async data => {
    userMapColors.value = data;
  });*/

  if (LAST_SELECTED_ELEMENT_TO_VIEW.value) {
    await getPublishedProfiles(LAST_SELECTED_ELEMENT_TO_VIEW.value);
    //await getCountryProfiles(LAST_SELECTED_ELEMENT_TO_VIEW.value);
  }

  if (route.query.profileType) {
    if (route.query.profileType === "COMPANY") {
      tableView.value = "COMPANY_PROFILES"
    } else if (route.query.profileType === "COUNTRY") {
      tableView.value = "COUNTRY_PROFILES"
    }
  }
});


onBeforeMount(() => {
  window.$loading.start();
})

</script>

<style lang="scss" scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.floating-menu {
  // width: 250px;
  // position: absolute;
  // margin: 10px 0 0 0px;
  width: 100%;
}

.mapbox-legend {
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  display: inline-block;

  &--red {
    background-color: #d83615;
  }

  &--blue {
    background-color: #293c4f;
  }

  &--green {
    background-color: #1c9099;
  }

  &--yellow {
    background-color: #e1b16a;
  }

  &--white {
    background-color: #293c4f;
    border: 1px solid black;
  }
}

// ROUNDED SCROLLBAR - START //
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgrey;
}

::-webkit-scrollbar-thumb:active {
  background: darkgrey;
}


/* Container for the menu and content */
.content-container {
  display: flex;
  transition: all 0.3s ease;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 15px;
}

/* Sidebar (Menu) */
.sidebar {
  width: 300px;
  color: #192E40;
  padding-right: 15px;
  transition: all 0.3s ease;
  position: relative;
}

/* Content Area */
.content {
  width: calc(100% - 250px);
  /* Adjust width when menu is visible */
  transition: width 0.3s ease;
}

/* Collapsed State */
.collapsed .sidebar {
  width: 25px;
  overflow: hidden;
  height: 62px;
}

.collapsed .content {
  width: 100%;
  height: 10%;
  /* Expand content to full width */
}

.sidebar-padding-show {
  padding-right: 15px !important;
}

.sidebar-padding-hide {
  padding-right: 25px !important;
}

.vertical-button {
  writing-mode: vertical-rl;
  /* Rotates text vertically */
  transform: rotate(180deg);
  /* Adjusts orientation */
  padding: 5px;
  height: 65px;
  width: 25px;
  font-size: 16px;
  background-color: #192E40;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  border: 2px solid white;
  z-index: 9999;
  top: -2px
}

.vertical-button.closed {
  right: 0px;
}

.vertical-button.open {
  right: -7px;
}

.tabs {
  display: flex;
  cursor: pointer;
  background: #EBEDEF;
  padding: 15px;
  border-radius: 5px;
}

.tab {
  width: 125px;
  height: 35px;
  padding: 4px 19px;
  margin-right: auto;
  background: white;
  border-radius: 5px;
  border: 1px solid lightgrey;
  margin-left: 5px;
  margin-right: 5px;
}

.button {
  width: 100%;
  height: 35px;
  padding: 5px 15px;
  background: #192E40;
  border-radius: 5px;
  color: white;
  border: 0px solid #192E40;
}

.tab.active {
  background: #192E40;
  color: white;
}

.tab-content {
  display: none;
  padding: 20px;
  margin-top: 10px;
  background: #EBEDEF;
  padding: 10px;
  border-radius: 5px;
}

.tab-content.active {
  display: block;
}

.chevron-left {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(-45deg);
  margin-left: 5px;
}

.chevron-right {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(135deg);
  margin-left: 17px;
}


input[type="checkbox"] {
  display: none;
}

/* Custom checkmark style */
.custom-checkbox {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
  margin-right: 15px;
}

.checkmark {
  width: 20px;
  height: 20px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -2px;
  background-color: #fff;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

/* When checked, show the tick (with color #f1ae31) */
input[type="checkbox"]:checked+.checkmark {
  background-color: #fff;
  border-color: #f1ae31;
}

/* Adding a checkmark when selected */
input[type="checkbox"]:checked+.checkmark::after {
  content: "✓";
  position: absolute;
  left: 4px;
  top: -1px;
  font-size: 14px;
  color: #f1ae31;
  /* Set checkmark color */
}

input[type="checkbox"]:checked+.operating {
  background-color: #fff;
  border-color: #1c9099;
}

/* Adding a checkmark when selected */
input[type="checkbox"]:checked+.operating::after {
  content: "✓";
  position: absolute;
  left: 4px;
  top: -1px;
  font-size: 14px;
  color: #1c9099;
  /* Set checkmark color */
}

input[type="checkbox"]:checked+.project {
  background-color: #fff;
  border-color: #e1b16a;
}

/* Adding a checkmark when selected */
input[type="checkbox"]:checked+.project::after {
  content: "✓";
  position: absolute;
  left: 4px;
  top: -1px;
  font-size: 14px;
  color: #e1b16a;
  /* Set checkmark color */
}

input[type="checkbox"]:checked+.closed {
  background-color: #fff;
  border-color: #d83615;
}

/* Adding a checkmark when selected */
input[type="checkbox"]:checked+.closed::after {
  content: "✓";
  position: absolute;
  left: 4px;
  top: -1px;
  font-size: 14px;
  color: #d83615;
  /* Set checkmark color */
}

/* Optional: styling label */
.custom-checkbox {
  padding-left: 25px;
  cursor: pointer;
}

/* Triangle Checkbox (Refinery) */
.custom-checkbox .checkmark-refinery {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #f1ae31;
  /* Border color */
  display: inline-block;
  position: relative;
  margin-right: -18px;
  left: -25px;
  transition: border-bottom-color 0.2s ease-in-out;
  top: 5px
}

/* Inner triangle for white fill (before checked) */
.custom-checkbox .checkmark-refinery::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid white;
  /* White fill */
}

/* When checked, fill the triangle */
input[type="checkbox"]:checked+.checkmark-refinery {
  border-bottom-color: #f1ae31;
  /* Same as border for full fill effect */
}

/* When checked, change inner triangle to match background */
input[type="checkbox"]:checked+.checkmark-refinery::before {
  border-bottom-color: #f1ae31;
}

/* Inner square for white fill (before checked) */
.custom-checkbox .checkmark-mine {
  width: 18px;
  height: 18px;
  border: 1px solid lightgray;
  /* Border color */
  background-color: white;
  /* White fill */
  display: inline-block;
  position: relative;
  margin-right: -18px;
  transition: background-color 0.2s ease-in-out;
  left: -25px;
  top: 5px
}

/* When checked, fill the square */
input[type="checkbox"]:checked+.checkmark-mine {
  background-color: #f1ae31;
  /* Filled effect */
}

//modal 
/* Confirmation Modal */
.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

.modal button {
  width: 45%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
}

.confirm-btn {
  background-color: #192E40;
  color: white;
}

.cancel-btn {
  background-color: #192E40;
  color: white;
}

.confirm-btn:hover {
  background-color: #EBEDEF;
  color: #f1ae31;
}

.cancel-btn:hover {
  background-color: #EBEDEF;
  color: #f1ae31;
}

.checkmark-box {
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 5px;
  background-color: white;
}

/* Border colors */
.gold {
  border-color: #e1b16a;
}

.blue {
  border-color: #1c9099;
}

.red {
  border-color: #d83615;
}


/* Checkmark */
.checkmark-box::after {
  content: "✔";
  font-size: 13px;
  position: absolute;
  top: 1.5px;
}

/* Checkmark colors */
.gold::after {
  color: #f1ae31;
}

.blue::after {
  color: #1c9099;
}

.red::after {
  color: #d83615;
}


.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #f1ae31;
  cursor: pointer;
  /* Change color */
}

.square {
  width: 20px;
  height: 20px;
  /* Outer color */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #f1ae31;
  background-color: #f1ae31;
}

.circle {
  width: 8px;
  /* Adjust size as needed */
  height: 8px;
  /* Must be equal to width for a perfect circle */
  /* Example color */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

// ROUNDED SCROLLBAR - END //</style>